<script setup lang="ts">
// @ts-nocheck
// needed because of #header and #content slots in <component>
import type { UseBreakpointsReturn } from '@vueuse/core';

const breakpoints: UseBreakpointsReturn = inject('breakpoints') as UseBreakpointsReturn;
const smallerThanLg = breakpoints.smaller('lg');
const component = computed(() => (smallerThanLg.value ? 'SharedAccordion' : 'LayoutFooterNavigationDesktop'));
const { serviceContact, groupedOpenings } = useContactInformation();
</script>

<template>
    <ClientOnly>
        <div class="border-b-1 lg:border-b-none">
            <component
                :is="component"
                id="footer-service-times"
                :open="false"
            >
                <template #header>
                    <div class="text-white lg:mr-7 lg:text-xl lg:font-bold lg:text-gray-200 lg:uppercase">
                        {{ $t('service.times.headline') }}
                    </div>
                </template>

                <template #content>
                    <div class="footer-service text-white mb-4 lg:py-4">
                        <p class="mb-0">
                            {{ $t('service.times.utc') }}
                        </p>

                        <template v-if="groupedOpenings">
                            <div
                                v-for="(schedule, index) in groupedOpenings"
                                :key="index"
                                class="flex flex-col mb-2.5"
                            >
                                <div>{{ schedule.day }}:</div>
                                <div>{{ schedule.time }}</div>
                            </div>
                        </template>

                        <p
                            v-if="serviceContact && serviceContact.businessPhone"
                            class="mb-0"
                        >
                            <NuxtLink
                                class="text-white hover:underline"
                                :title="serviceContact.businessPhone"
                                :href="'tel:' + serviceContact.businessPhone"
                            >
                                <strong>{{ serviceContact.businessPhone }}</strong>
                            </NuxtLink>
                        </p>

                        <p
                            v-if="serviceContact?.email"
                            class="mb-0"
                        >
                            <strong>
                                <NuxtLink
                                    class="text-white hover:underline"
                                    :title="serviceContact.email"
                                    :href="`mailto:${serviceContact.email}`"
                                >
                                    {{ serviceContact.email }}
                                </NuxtLink>
                            </strong>
                        </p>
                    </div>
                </template>

                <template #icon="{ isOpen }">
                    <LazySvgoAngleDown
                        v-if="smallerThanLg"
                        class="text-white h-5 w-auto"
                        :class="{
                            'rotate-180': isOpen,
                            'rotate-0': !isOpen,
                        }"
                    />
                </template>
            </component>
        </div>

        <template #fallback>
            <LayoutFooterNavigationSkeleton
                :nav-elements="[{ children: [{}, {}, {}, {}, {}, {}] }]"
                hide-all
            />
        </template>
    </ClientOnly>
</template>
